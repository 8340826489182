import React, { FC, ReactElement } from "react";
import { Button, Modal, MediaPlayer } from "@epignosis_llc/gnosis";
import { modalFooter, modalHeader, previewModal } from "./styles";
import { File } from "types/entities";
import { downloadFile, removeFileExtension } from "@utils/helpers";
import PdfViewer from "@components/PdfViewer/PdfViewer";
import { t, i18n } from "@utils/i18n";
import { useApplyTranslations } from "@hooks";

export type FileModalProps = File & {
  isOpen: boolean;
  showExtension?: boolean;
  onClose: () => void;
};

const filePreview = (mime_type: string, url: string, fileName: string): ReactElement => {
  const isRtl = i18n.dir() === "rtl";
  switch (mime_type) {
    case "video/mp4":
      return <MediaPlayer type="video" src={url} />;
    case "audio/mpeg":
      return <MediaPlayer type="audio" src={url} />;
    case "application/pdf":
    case "application/msword":
    case "application/vnd.ms-excel":
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/powerpoint": // this mime type is returned by the API for ptt files
      return (
        <div className="pdf-container">
          <PdfViewer fileUrl={url} isRTL={isRtl} />
        </div>
      );
    case "image/gif":
    case "image/png":
    case "image/jpeg":
      return (
        <div className="img-container">
          <img src={url} alt={fileName} />
        </div>
      );
    default:
      return <p>{t("myFiles.noPreviewAvailable")}</p>;
  }
};

const FileModal: FC<FileModalProps> = ({
  id,
  name,
  url,
  mime_type,
  isOpen,
  showExtension = false,
  preview_url,
  onClose,
}) => {
  const { t } = useApplyTranslations();
  const fileName = showExtension ? name : removeFileExtension(name);

  const handleDownloadFile = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    e.preventDefault();
    await downloadFile({ fileUrl: url, fileName: name, fileId: id });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <Modal.Header>
        <div css={modalHeader}>{fileName}</div>
      </Modal.Header>
      <Modal.Body>
        <div css={previewModal}>{filePreview(mime_type, preview_url, fileName)} </div>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          <Button color="primary" onClick={handleDownloadFile}>
            {t("general.download")}
          </Button>
          <Button color="secondary" onClick={onClose} className="cancel-btn">
            {t("general.cancel")}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default FileModal;
