import React, { FC } from "react";
import { courseOverviewStyles } from "./styles";
import Header, { HeaderProps } from "./compoundComponents/Header";
import Content from "./compoundComponents/Content";

type CourseOverviewProps = {
  Header: FC<HeaderProps>;
  Content: FC;
};

const CourseOverview: FC & CourseOverviewProps = ({ children }) => (
  <div css={courseOverviewStyles} id="course-overview">
    {children}
  </div>
);

CourseOverview.Header = Header;
CourseOverview.Content = Content;

export default CourseOverview;
