// Packages or third-party libraries
import React, { FC, useReducer, useState } from "react";
import { DragDropContext, Draggable, DropResult, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

// Components
import { ToC } from "@components";
import UnitListItem from "./UnitListItem";
import UploadLoader from "./UploadLoader";

// Utils, stores, hooks
import { reorderSingleUnit, reorderMultipleUnits } from "@views/CourseEdit/helpers";
import { sectionReorderReducer } from "@views/CourseEdit/reducers";

// Other imports
import { Course, MyUnit, Section } from "types/entities";
import { UnitEditRouterParams } from "@views/UnitEdit/types";
import { putUnitsOrder } from "@views/CourseEdit/api";
import { ReorderActions } from "@views/CourseEdit/constants";
import queryKeys from "@constants/queryKeys";

type UnitListProps = {
  course: Course;
  flatUnits: (Section | MyUnit)[];
  listWrapperRef: React.RefObject<HTMLDivElement>;
  postFileLoading: boolean;
  showUnitSidebar: boolean;
  handleFlatUnitsChange: (flatUnits: (Section | MyUnit)[]) => void;
};

const UnitList: FC<UnitListProps> = ({
  course,
  flatUnits,
  listWrapperRef,
  postFileLoading,
  showUnitSidebar,
  handleFlatUnitsChange,
}) => {
  const { policies } = course ?? {};
  const queryClient = useQueryClient();

  const { can_update_content } = policies ?? {};
  const canReorderUnits = Boolean(can_update_content);
  const { courseId } = useParams() as UnitEditRouterParams;

  const [isDragging, setIsDragging] = useState(false);
  // used to keep the state of full section reordering
  const [sectionReorderState, reorderDispatch] = useReducer(sectionReorderReducer, {
    selectedSection: null,
    selectedUnits: [],
  });
  const { selectedSection, selectedUnits } = sectionReorderState;

  const handleDragStart = (): void => {
    setIsDragging(true);
  };

  // handle units reordering
  const handleDragEnd = (result: DropResult): void => {
    setIsDragging(false);
    const { source, destination } = result;

    if (!canReorderUnits) return;
    if (!destination) return;

    const reorderUnits = !selectedSection
      ? reorderSingleUnit([...flatUnits], source.index, destination.index)
      : reorderMultipleUnits({
          list: [...flatUnits],
          itemsToMove: [selectedSection.id].concat(selectedUnits),
          source,
          destination,
        });

    handleFlatUnitsChange(reorderUnits);

    enrollmentMutation({
      courseId,
      unitIds: reorderUnits.map((unit) => unit.id),
    });

    if (selectedSection) {
      reorderDispatch({
        type: ReorderActions.unsetSection,
        payload: {
          selectedSection: null,
          selectedUnits: [],
        },
      });
    }
  };

  const { mutate: enrollmentMutation } = useMutation(
    [queryKeys.courses.unitsOrder],
    ({ courseId, unitIds }: { courseId: string; unitIds: number[] }) =>
      putUnitsOrder(courseId, unitIds),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.units, courseId]);
      },
    },
  );

  return (
    <div ref={listWrapperRef}>
      <ToC.ListContainer isOpen={showUnitSidebar}>
        <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
          <Droppable droppableId="course-units" isDropDisabled={!canReorderUnits}>
            {(provided): JSX.Element => {
              return (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {flatUnits.map((flatsUnit, index) => {
                    const { id } = flatsUnit;

                    // disabled dnd when can not update course content or
                    // full section reordering is enabled and unit is not the selected section
                    const isDragDisabled =
                      !canReorderUnits || Boolean(selectedSection && selectedSection.id !== id);

                    return (
                      <Draggable
                        key={id}
                        draggableId={id.toString()}
                        index={index}
                        disableInteractiveElementBlocking={true}
                        isDragDisabled={isDragDisabled}
                      >
                        {(provided, snapshot): JSX.Element => {
                          // unit is reordered:
                          // - on dragging
                          // - on full section reordering (selected section)
                          // - on full section reordering (unit of the selected section)
                          const isReordered = Boolean(
                            snapshot.isDragging ||
                              selectedSection?.id === id ||
                              selectedUnits.includes(id),
                          );

                          return (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <UnitListItem
                                key={`section-${id}`}
                                course={course}
                                unit={flatsUnit}
                                canBeReordered={canReorderUnits}
                                isReordered={isReordered}
                                sectionReorderState={sectionReorderState}
                                reorderDispatch={reorderDispatch}
                                provided={provided}
                                isDragging={isDragging}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              );
            }}
          </Droppable>
        </DragDropContext>

        {postFileLoading && <UploadLoader />}
      </ToC.ListContainer>
    </div>
  );
};

export default UnitList;
