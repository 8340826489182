import { URLS } from "@constants/urls";
import create, { StoreApi, UseBoundStore } from "zustand";
import { persist } from "zustand/middleware";
import { useConfigurationStore } from "@stores";
import { Pagination as IPagination } from "types/responses/index";
import { QueryFilter } from "types/common";

export type PageSettings = {
  sorting?: string[];
  filters?: object | QueryFilter[];
  pagination?: IPagination;
  // The following properties are used by the training matrix component
  showCourseScore?: boolean;
  showCourseProgress?: boolean;
  focusedOn?: string;
  // The following properties are used by the unit matrix component
  focusedOnOption?: string;
};

type Settings = Record<string, PageSettings>;

type UserPreferencesProps = {
  settings: Settings;
  setSettings: (settings: PageSettings, path: string) => void;
  resetSettings: () => void;
};

const initialSettings = {
  [`${URLS.user.courses}/inProgress`]: {
    sorting: ["-enroll_date"],
  },
  [`${URLS.user.courses}/completed`]: {
    sorting: ["-enroll_date"],
  },
  [URLS.catalog.index]: {
    sorting: ["-last_updated_on"],
  },
  [URLS.user.files]: {
    sorting: ["-uploaded_at"],
  },
  [URLS.user.calendar]: {
    sorting: [],
    filters: {
      eventType: "enrolled",
      view: "month",
    },
  },
  [URLS.user.groups]: {
    sorting: ["name"],
  },
  [URLS.reports.reports]: {
    focusedOn: "all",
    showCourseScore: false,
    showCourseProgress: false,
    sorting: ["name", "name"],
  },
  [`${URLS.reports.courseReports}/matrix`]: {
    focusedOn: "all",
    sorting: ["name"],
    focusedOnOption: "none",
  },
};

const initialTableSettings = {
  // Users / User
  [URLS.users.users]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/groups`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/branches`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/payments`]: {
    sorting: ["-date"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.users.user}/files`]: {
    sorting: ["-uploaded_at"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Groups / Group
  [URLS.groups.groups]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.groups.group}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.groups.group}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.groups.group}/files`]: {
    sorting: ["-uploaded_at"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Branches / Branch
  [URLS.branches.branches]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.branches.branch}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.branches.branch}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.branches.branch}/files`]: {
    sorting: ["-uploaded_at"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Courses / Course
  [URLS.courses.courses]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.courses.singleCourse}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.courses.singleCourse}/branches`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.courses.singleCourse}/groups`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Categories
  [URLS.categories.categories]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Messages
  [`${URLS.user.messages}/inbox`]: {
    sorting: ["-sent_at"],
    filters: {
      sender: null,
      dateFrom: null,
      dateUntil: null,
      attachment: "all",
    },
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.user.messages}/sent`]: {
    sorting: ["-sent_at"],
    filters: {
      sender: null,
      dateFrom: null,
      dateUntil: null,
      attachment: "all",
    },
    pagination: { number: 1, size: 10 },
  },
  // Discussions
  [URLS.user.discussions]: {
    sorting: ["-latest_reply"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Certificates
  [URLS.user.certificates]: {
    sorting: ["course_name"],
    filters: {
      dateFromIssueAt: null,
      dateUntilIssueAt: null,
      dateFromExpiresAt: null,
      dateUntilExpiresAt: null,
    },
    pagination: { number: 1, size: 10 },
  },
  // Account & Settings
  [`${URLS.accountAndSettings.accountAndSettings}/language-overrides`]: {
    sorting: ["original"],
  },
  [`${URLS.accountAndSettings.accountAndSettings}/user-types`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [URLS.accountAndSettings.customUserFields]: {
    sorting: ["custom"],
    pagination: { number: 1, size: 10 },
  },
  [URLS.accountAndSettings.customCourseFields]: {
    sorting: ["custom"],
    pagination: { number: 1, size: 10 },
  },
  // Reports
  [`${URLS.reports.user}/units`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.user}/courses`]: {
    sorting: ["-completion_status"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.user}/certificates`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [URLS.reports.userReports]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [URLS.reports.courseReports]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [URLS.reports.branchReports]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [URLS.reports.groupReports]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.branchReports}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.branchReports}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.groupReports}/users`]: {
    sorting: ["formatted_name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.groupReports}/courses`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.courseReports}/units`]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [`${URLS.reports.reports}/scheduledReports`]: {
    sorting: ["type"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  [URLS.reports.customReports]: {
    sorting: ["name"],
    filters: [],
    pagination: { number: 1, size: 10 },
  },
  // Course store
  [URLS.courseStore.index]: {
    sorting: ["name"],
    filters: [{ key: "[language]", value: "english" }],
    pagination: { number: 1, size: 10 },
  },
};

const userStores: Record<string, StoreApi<UserPreferencesProps>> = {};
const defaultStoreKey = "default-store";

const useUserPreferencesStore = (): UseBoundStore<
  UserPreferencesProps,
  StoreApi<UserPreferencesProps>
> => {
  const { getState: getConfigurationState } = useConfigurationStore;
  const userId = getConfigurationState().userProfileData?.id;
  const storeKey = `user-store-${userId}`;

  const persistSettings = persist<UserPreferencesProps>(
    (set, get) => {
      return {
        settings: { ...initialSettings, ...initialTableSettings },
        setSettings: (newSettings: PageSettings, path: string): void => {
          const clonedSettings = { ...get().settings };
          clonedSettings[path] = newSettings;
          set((state) => ({ ...state, settings: clonedSettings }));
        },
        resetSettings: (): void => {
          // Reset settings to the initialTableSetting
          set((state) => ({
            settings: { ...state.settings, ...initialTableSettings },
          }));
        },
      };
    },
    {
      name: storeKey, // Unique store key based on user ID
      getStorage: () => localStorage,
    },
  );

  if (userId && !userStores[userId]) {
    userStores[userId] = create<UserPreferencesProps>(persistSettings);
  }
  if (!userStores[defaultStoreKey]) {
    userStores[defaultStoreKey] = create<UserPreferencesProps>(persistSettings);
  }

  return userId
    ? (userStores[userId] as UseBoundStore<UserPreferencesProps, StoreApi<UserPreferencesProps>>)
    : (userStores[defaultStoreKey] as UseBoundStore<
        UserPreferencesProps,
        StoreApi<UserPreferencesProps>
      >);
};

export default useUserPreferencesStore;
