// Packages or third-party libraries
import React, { FC, useState } from "react";
import { DropdownItem } from "@epignosis_llc/gnosis";
import { LockIconSVG, LockOpenSVG, ShareSVG } from "@epignosis_llc/gnosis/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { AxiosError } from "axios";

// Components
import { ToC } from "@components";
import ShareDrawer from "../ShareDrawer/ShareDrawer";
import PublishButton from "./components/PublishButton";
import SidebarIcons from "./components/SidebarIcons/SidebarIcons";

// Utils, stores, hooks
import { t } from "@utils/i18n";
import { useUnitStore } from "@stores";
import { useEditCourseDetails } from "@views/CourseEdit/hooks";
import { handleCourseErrors } from "@errors";

// Other imports
import { Course, MyUnit, Section } from "types/entities";
import { UnitEditRouterParams } from "@views/UnitEdit/types";
import queryKeys from "@constants/queryKeys";
import { CourseActions } from "@views/CourseEdit/constants";
import { URLS } from "@constants/urls";

type ToCHeaderProps = {
  course: Course;
  flatUnits: (Section | MyUnit)[];
  isOpen: boolean;
};

const getDropdownOptions = (policies: Course["policies"], isLocked: boolean): DropdownItem[] => {
  const { can_share = false, can_update_locked = false } = policies ?? {};
  const options = [];

  if (can_update_locked) {
    options.push({
      label: isLocked ? t("courseEdit.unlockCourse") : t("courseEdit.lockCourse"),
      value: isLocked ? CourseActions.Unlock : CourseActions.Lock,
      icon: isLocked ? <LockOpenSVG height={32} /> : <LockIconSVG height={32} />,
    });
  }

  if (can_share) {
    options.push({
      label: t("courseEdit.share"),
      value: CourseActions.Share,
      icon: <ShareSVG height={32} />,
    });
  }

  return options;
};

const ToCHeader: FC<ToCHeaderProps> = ({ course, flatUnits, isOpen }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setCanAutoSaveUnit = useUnitStore((state) => state.setCanAutoSaveUnit);
  const { courseId, unitId } = useParams() as UnitEditRouterParams;

  const [isSharedDrawerOpen, setIsSharedDrawerOpen] = useState(false);

  const { name, policies, is_locked = false } = course;
  const dropdownOptions = getDropdownOptions(policies, is_locked);

  const toggleShareDrawer = (): void => {
    setIsSharedDrawerOpen((prev) => !prev);
  };

  const { mutate: editCourseLockMutation } = useEditCourseDetails({
    courseId,
    options: {
      onSuccess: async () => {
        queryClient.invalidateQueries([queryKeys.myCourse, courseId]);
        queryClient.invalidateQueries([queryKeys.units, courseId]);

        // invalidate also unit when lock/unlock course from unit edit page
        if (unitId) {
          queryClient.invalidateQueries([queryKeys.unit, unitId]);
        }
      },
      onError: (err) => {
        const error = err as AxiosError;
        const handleError = (): void => {
          navigate(URLS.courses.courses);
        };

        handleCourseErrors(error, false, handleError);
      },
    },
  });

  const handleDropdownOptionSelect = ({ value }: DropdownItem): void => {
    switch (value) {
      case CourseActions.Share:
        toggleShareDrawer();
        break;
      default: {
        editCourseLockMutation({ is_locked: !is_locked });
        setCanAutoSaveUnit(is_locked);
      }
    }
  };

  return (
    <>
      <ToC.Header
        title={name}
        titleLink={URLS.courses.getCourseEditLink({ courseId })}
        isOpen={isOpen}
        dropdownOptions={dropdownOptions}
        onDropdownOptionSelect={handleDropdownOptionSelect}
      >
        <>
          <SidebarIcons course={course} toggleShareDrawer={toggleShareDrawer} />
          <PublishButton course={course} flatUnits={flatUnits} />
        </>
      </ToC.Header>

      {isSharedDrawerOpen && (
        <ShareDrawer isOpen={isSharedDrawerOpen} course={course} onClose={toggleShareDrawer} />
      )}
    </>
  );
};

export default ToCHeader;
