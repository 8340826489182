export default {
  domainSettings: "domain-settings",
  ssoDomainSettings: "sso-domain-settings",
  socialDomainSettings: "social-domain-settings",
  autologin: "autologin",
  activateUser: "activate-user",
  autologinToken: "autologin-token",
  gamificationSettings: "gamification-settings",
  gamificationResetStats: "gamification-reset-stats",
  userProfile: "user-profile",
  userPassword: "user-password",
  userAvatar: {
    post: "user-avatar",
    delete: "delete-user-avatar",
  },
  processEvent: "process-event",
  analytics: {
    trackEvent: "track-event",
  },
  accountAndSettings: {
    securitySettings: {
      getSecuritySettings: "get-security-settings",
      saveSecuritySettings: "save-security-settings",
    },
    export: {
      availableTypes: "available-export-types",
      create: "create-export",
    },
    import: {
      import: "import-file",
    },
    ftpSync: {
      saveServerConfig: "save-server-config",
      getServerConfig: "get-server-config",
      getExportConfig: "get-export-config",
      getImportConfig: "get-import-config",
      saveImportConfig: "save-import-config",
      saveExportConfig: "save-export-config",
      testConnection: "test-connection",
      importNow: "import-now",
      exportNow: "export-now",
    },
    courseSettings: {
      addCustomCourseField: "add-custom-course-field",
      deleteCustomCourseField: "delete-custom-course-field",
      editCustomCourseField: "edit-custom-course-field",
      editCustomCourseFieldOrder: "edit-custom-course-field-order",
      getCourseSettings: "get-course-settings",
      getCustomCourseField: "get-custom-course-field",
      getCustomCourseFields: "get-custom-course-fields",
      updateCourseSettings: "update-course-settings",
      addCustomCertificate: "add-custom-certificate",
      getCustomCertificate: "get-custom-certificate",
      getCustomCertificates: "get-custom-certificates",
      getCertificateTemplateBackgrounds: "get-certificate-template-backgrounds",
      getCertificateTemplateBody: "get-certificate-template-body",
      getCertificateSmartTags: "get-certificate-smart-tags",
      cloneCustomCertificate: "clone-custom-certificate",
      resetCustomCertificate: "reset-custom-certificate",
      editCustomCertificate: "edit-custom-certificate",
      deleteCustomCertificate: "delete-custom-certificate",
      deleteCertificateBackground: "delete-certificate-background",
      countAffectedCourses: "count-affected-courses",
      previewCustomCertificate: "preview-custom-certificate",
      uploadCertificateTemplateBackground: "upload-certificate-template-background",
    },
    customHomepage: {
      createCustomHomepageItem: "create-custom-homepage-item",
      createCustomHomepageSection: "create-custom-homepage-section",
      cloneCustomHomepage: "clone-custom-homepage",
      deleteCustomHomepageItem: "delete-custom-homepage-item",
      deleteCustomHomepageSection: "delete-custom-homepage-section",
      deleteCustomHomepageAllSections: "delete-custom-homepage-all-sections",
      getCustomHomepage: "get-custom-homepage",
      getCustomHomepageCourses: "get-custom-homepage-courses",
      getCustomHomepageSections: "get-custom-homepage-sections",
      updateCustomHomepage: "update-custom-homepage",
      updateCustomHomepageSection: "update-custom-homepage-section",
      updateCustomHomepageItem: "update-custom-homepage-item",
      updateCustomHomepageOrder: "update-custom-homepage-order",
      updateCustomHomepageSectionsOrder: "update-custom-homepage-sections-order",
      uploadCustomHomepageMediaSectionVideo: "upload-custom-homepage-section-video",
    },
    ecommerceSettings: {
      getEcommerceSettings: "get-ecommerce-settings",
      saveEcommerceSettings: "save-ecommerce-settings",
      saveEcommerceCredits: "save-ecommerce-credits",
      deleteEcommerceCredits: "delete-ecommerce-credits",
      getEcommerceUsers: "get-ecommerce-users",
      getEcommerceGroups: "get-ecommerce-groups",
      getEcommerceBranches: "get-ecommerce-branches",
      getEcommerceInvoices: "get-ecommerce-invoices",
      getEcommerceCoupons: "get-ecommerce-coupons",
      getEcommerceSingleCoupon: "get-ecommerce-single-coupon",
      editEcommerceCoupon: "edit-ecommerce-coupon",
      deleteEcommerceCoupon: "delete-ecommerce-coupon",
      getEcommercePaidCourses: "get-ecommerce-paid-courses",
      getEcommercePaidGroups: "get-ecommerce-paid-groups",
      getEcommercePaidCategories: "get-ecommerce-paid-categories",
      addEcommerceCoupon: "add-ecommerce-coupon",
    },
    gamification: {
      badges: "gamification-badges",
      badgeSets: "gamification-badge-sets",
      resetBadge: "reset-badge",
      updateActiveBadgeSet: "update-active-badge-set",
      updateBadge: "update-badge",
    },
    portalSettings: {
      deleteCustomDomainName: "delete-custom-domain-name",
      generateCSRKey: "generate-csr-key",
      getCustomDomainNameValidate: "get-custom-domain-name-validate",
      getCustomDomainSettings: "get-custom-domain-settings",
      getCustomEmailSettings: "get-custom-email-settings",
      getPortalSettings: "get-portal-settings",
      updateCustomEmailSettings: "update-custom-email-settings",
      updateCustomSSLSettings: "update-custom-ssl-settings",
      updatePortalSettings: "update-portal-settings",
      updateDomainNameSettings: "update-domain-name-settings",
      updateCustomDomainName: "update-custom-domain-name",
      communicationsSkipped: "communications-skipped",
      communicationsAcceptConsent: "communications-accept",
    },
    integrationsSettings: {
      getIntegrationsSettings: "get-integrations-settings",
      getApiSettings: "get-api-settings",
      saveApiSettings: "save-api-settings",
      getXApiSettings: "get-xapi-settings",
      saveXApiSettings: "save-xapi-settings",
      getIntercomSettings: "get-intercom-settings",
      saveIntercomSettings: "save-intercom-settings",
      getBambooHRSettings: "get-bamboohr-settings",
      saveBambooHRSettings: "save-bamboohr-settings",
      syncBambooHRSettings: "sync-bamboohr-settings",
      getConferencesSettings: "get-conferences-settings",
      postConferencesSettings: "post-conferences-settings",
      getStripeSettings: "get-stripe-settings",
      patchStripeSettings: "patch-stripe-settings",
      getStripeConnect: "get-stripe-connect",
      patchPayPalSettings: "patch-paypal-settings",
      getPayPalSettings: "get-paypal-settings",
    },
    userSettings: {
      addLanguageOverride: "add-language-override",
      addCustomUserField: "add-custom-user-field",
      editCustomUserField: "edit-custom-user-field",
      editCustomUserFieldOrder: "edit-custom-user-field-order",
      getCustomUserField: "get-custom-user-field",
      getCustomUserFields: "get-custom-user-fields",
      deleteCustomUserField: "delete-custom-user-field",
      deleteLanguageOverride: "delete-language-override",
      downloadLanguageOverrides: "download-language-overrides",
      editLanguageOverride: "edit-language-override",
      generateSCIMKey: "generate-scim-key",
      getUsersSettings: "get-users-settings",
      getLanguageOverrides: "get-language-overrides",
      getLanguageOverridesCount: "get-language-overrides-count",
      getSSOSettings: "get-sso-settings",
      getTestSSOConfigData: "get-test-sso-config-data",
      updateSSOSettings: "update-sso-settings",
      updateUsersSettings: "update-users-settings",
      ssoLDAPLogoutURL: "sso-ldap-logout-url",
      testSSOConfig: "test-sso-config",
    },
    subscription: {
      updateCreditCard: "update-credit-card",
      cancelSubscription: "cancel-subscription",
      invoices: "domain-invoices",
      downloadInvoice: "download-invoice",
      plans: "subscription-plans",
      currentPlan: "current-plan",
      invoiceInfo: "invoice-info",
      updateBillingAddress: "update-billing-address",
      updateRecipients: "update-recipients",
      reactivateSubscription: "reactivate-subscription",
      downgradeToFree: "downgrade-to-free",
      couponValidation: "coupon-validation",
      planEstimates: "plan-estimates",
      purchasePlan: "purchase-plan",
    },
  },
  myCourses: "my-courses",
  user: {
    update: "user-update",
    create: "user-create",
    delete: "user-delete",
  },
  myFiles: "my-files",
  myGroups: "my-groups",
  myProgress: {
    courses: "my-progress-courses",
    units: "my-progress-units",
    timeline: "my-progress-timeline",
  },
  files: {
    tags: "tags",
  },
  groups: {
    availableCourses: "available-courses",
    affectedGroups: "affected-groups",
    addCourse: "add-course",
    addCourseToAllGroups: "add-course-to-all-groups",
    addUserCourses: "add-user-courses",
    addUser: "add-user",
    availableUsers: "available-users",
    create: "group-create",
    courses: "courses",
    delete: "delete",
    deleteFile: "delete-file",
    files: "group-files",
    groupsByUsers: "groups-by-users",
    groups: "groups",
    group: "group",
    groupOwner: "group-owner",
    postFile: "post-file",
    removeCourse: "remove-course",
    removeUser: "remove-user",
    removeUserCourses: "remove-user-courses",
    removeCourseFromAllGroups: "remove-course-from-all-groups",
    users: "users",
    updateFile: "update-file",
    update: "group-update",
  },
  allGroups: "all-groups",
  myBranches: "my-branches",
  myBranchesProfileMenu: "my-branches-profile-menu",
  branches: {
    addCourse: "add-course",
    addUser: "add-user",
    affectedBranches: "affected-branches",
    addCourseToAllBranches: "add-course-to-all-branches",
    availableCourses: "available-courses",
    availableUsers: "available-users",
    branches: "branches",
    branch: "branch",
    branchesByUsers: "branches-by-users",
    courses: "courses",
    create: "branch-create",
    getBranchTestSSOConfigData: "get-branch-test-sso-config-data",
    update: "branch-update",
    clone: "clone-branch",
    delete: "delete-branch",
    files: "branch-files",
    postFile: "post-file",
    updateFile: "update-file",
    deleteFile: "delete-file",
    testSSOConfig: "test-sso-config",
    removeCourseFromAllGroups: "remove-course-from-all-groups",
    removeCourse: "remove-course",
    removeUser: "remove-user",
    users: "users",
    loginBranches: "login-branch",
  },
  joinGroup: "join-group",
  myCertificates: "my-certificates",
  myBadges: "my-badges",
  rewards: "my-rewards",
  allBadges: "all-badges",
  catalog: "catalog",
  catalogCategories: "catalog-categories",
  topCategories: "top-categories",
  myCourse: "my-course",
  course: "course",
  unit: "unit",
  sampleUnit: "sample-unit",
  units: "units",
  unitTestExport: "unit-test-export",
  unitEnter: "enter",
  unitResults: "unit-results",
  achievements: "achievements",
  leaderboard: "leaderboard",
  userStatistics: "user-statistics",
  searchResults: "search-results",
  customFields: "custom-fields",
  userIntegrations: "user-integrations",
  assignmentUnit: {
    freeTextReply: "assignment-free-text-reply",
    fileUpload: "assignment-file-upload",
    reply: "assignment-reply",
  },
  iltUnit: {
    cloneSession: "clone-ilt-session",
    createSession: "create-ilt-session",
    deleteSession: "delete-ilt-session",
    sessionData: "ilt-session-data",
    sessions: "ilt-sessions",
    unitSessions: "ilt-unit-sessions",
    register: "session-register",
    unregister: "session-unregister",
    updateSession: "update-ilt-session",
  },
  rating: "rating",
  unitPassword: "unit-password",
  unitSnapshot: "unit-snapshot",
  unitStart: "unit-start",
  unitReset: "unit-reset",
  messages: {
    message: "message",
    inbox: "messages-inbox",
    sent: "messages-sent",
    delete: "message-delete",
    create: "message-create",
    reply: "message-reply",
    forward: "message-forward",
    recipients: "message-recipients",
    attachment: "message-attachment",
    unreadMessages: "unread-messages",
  },
  referral: {
    checkTos: "referral-check-tos",
    getReferrerInfo: "get-referrer-info",
    getRewards: "referrer-rewards",
    updateReward: "update-reward",
  },
  discussions: {
    discussion: "discussion",
    active: "discussions-active",
    singleDiscussion: "discussion-thread",
    delete: "discussion-delete",
    editDiscussion: "discussion-edit",
    deleteComment: "discussion-delete-comment",
    deleteRply: "discussion-delete-reply",
    create: "discussion-create",
    replies: "discussion-replies",
    comment: "discussion-comment",
    editComment: "discussion-comment-edit",
    reply: "discussion-comment-reply",
    audience: "discussions-audience",
    attachment: "discussions-attachment",
    like: "discussion-like",
    unlike: "discussion-unlike",
  },
  dashboard: {
    today: "today",
    todayInstructor: "today-instructor",
    dontMiss: "dont-miss",
    portalActivity: "portal-activity",
    coursePie: "course-pie",
    overviewWidget: "overview-widget",
    recentCourseActivity: "recent-course-activity",
  },
  search: {
    spotlight: "spotlight",
  },
  customHomePage: {
    sections: "custom-sections",
    menu: "custom-menu",
  },
  calendar: {
    deleteEvent: "delete-event",
    events: "calendar-events",
    editEvent: "edit-event",
    export: "calendar-export",
    statistics: "calendar-statistics",
    sync: "calendar-sync",
    syncReset: "calendar-sync-reset",
    specificAudience: "specific-audience",
  },
  contentTokens: "content-tokens",
  courseEnrollment: "course-enrollment",
  courseSubscription: "course-subscription",
  bundles: "catalog-bundles",
  branchSwitch: "branch-switch",
  catalogSettings: "catalog-settings",
  announcements: {
    announcements: "announcements",
    close: "announcements-close",
  },
  domainTerms: {
    terms: "domain-terms",
    accept: "accept-domain-terms",
  },
  couponValidation: "coupon-validation",
  themes: {
    themes: "themes",
    active: "active-theme",
    create: "create-theme",
    edit: "edit-theme",
    delete: "delete-theme",
  },
  courses: {
    addGroup: "add-group",
    availableGroups: "available-groups",
    addBranch: "add-branch",
    availableBranches: "available-branches",
    branches: "branches",
    clone: "course-clone",
    create: "course-create",
    copyFromDomainLogin: "copy-from-domain-login",
    copyFromDomainList: "copy-from-domain-list",
    copyFromDomain: "copy-from-domain",
    courses: "courses",
    coursesLight: "courses-light",
    courseEdit: "course-edit",
    customFields: "course-custom-fields",
    delete: "delete-course",
    deleteFiles: "delete-course-files",
    deleteIntroVideo: "delete-course-intro-video",
    files: "course-files",
    images: "course-images",
    getLinkedUnits: "get-linked-units",
    groups: "groups",
    patchUser: "patch-course-user",
    postImage: "post-course-image",
    postFile: "post-course-file",
    postUnit: "post-course-unit",
    prerequisiteOptions: "prerequisite-options",
    publish: "course-publish",
    removeGroup: "remove-group",
    removeBranch: "remove-branch",
    rulesEdit: "course-rules-edit",
    sectionCreate: "section-create",
    tags: "course-tags",
    unenrolledCourseUsers: "unenrolled-course-users",
    unitClone: "unit-clone",
    unitCreate: "unit-create",
    unitDelete: "unit-delete",
    unitExit: "unit-exit",
    unitLink: "unit-link",
    unitMigrate: "unit-migrate",
    unitPublish: "unit-publish",
    unitUpdate: "unit-update",
    unitsOrder: "units-order",
    unitSmartTags: "unit-smart-tags",
    users: "course-users",
    editUser: "edit-user",
    share: "share-course",
    sharedNotification: "course-shared-notification",
    syncProgress: "sync-progress",
    updateExpirationDate: "update-expiration-date",
    updateFile: "update-course-file",
    versionHistory: "unit-version-history",
    youtubeSearch: "youtube-search",
  },
  signin: "signin",
  signup: {
    signup: "signup",
    verify: "verify",
  },
  passwordReset: {
    requestReset: "request-reset",
    verifyCode: "verify-code",
    passwordReset: "password-reset",
  },
  passwordCreate: {
    passwordCreate: "password-create",
    verifyCode: "verify-code",
    resendCode: "resend-code",
  },
  changePassword: "change-password",
  invoices: {
    myInvoices: "my-invoices",
    invoice: "invoice",
    invoiceDownload: "invoice-download",
    updateCreditCard: "update-credit-card",
    userInvoices: "user-invoices",
  },
  courseUnits: "course-units",
  courseUnenroll: "course-unenroll",
  categories: {
    categories: "categories",
    create: "category-create",
    delete: "category-delete",
  },
  courseStore: {
    acquire: "course-store-acquire",
    categories: "course-store-categories",
    course: "course-store-course",
    courses: "course-store-courses",
    massAcquire: "course-store-mass-acquire",
    massAcquireCount: "course-store-mass-acquire-count",
    units: "course-store-units",
  },
  unitUsers: "unit-users",
  automations: {
    automations: "automations",
    delete: "automation-delete",
    conditions: "automation-conditions",
    create: "automation-create",
    edit: "automation-edit",
  },
  users: {
    activate: "activate",
    activateCount: "activate-count",
    assignableTypes: "assignable-types",
    addGroup: "add-group",
    addToGroups: "add-to-group",
    addToGroupsCount: "add-to-group-count",
    addToBranches: "add-to-branches",
    addToBranchesCount: "add-to-branches-count",
    badges: "badges",
    files: "user-files",
    postFile: "post-file",
    updateFile: "update-file",
    deleteFile: "delete-file",
    deleteUsersCount: "delete-users-count",
    deleteUsers: "delete-users",
    deactivate: "deactivate",
    deactivateCount: "deactivate-count",
    users: "users",
    user: "user",
    enrollToCourse: "enroll-user",
    update: "update",
    create: "create",
    courses: "courses",
    delete: "delete",
    groups: "groups",
    branches: "branches",
    availableGroups: "available-groups",
    availableCourses: "available-courses",
    units: "user-units",
    completeUnit: "complete-unit",
    completeCourse: "complete-course",
    removeFromGroupsCount: "remove-from-groups-count",
    removeFromGroups: "remove-from-groups",
    removeFromBranchesCount: "remove-from-branches-count",
    removeFromBranches: "remove-from-branches",
    resetUnit: "reset-unit",
    availableBranches: "available-branches",
    impersonation: "impersonation",
    stopImpersonation: "stop-impersonation",
    userCertificates: "user-certificates",
  },
  userTypes: {
    get: "get-user-types",
    edit: "edit-user-types",
    create: "create-user-types",
    delete: "delete-user-types",
    export: "export-user-types",
  },
  widgetsDashboard: {
    widgets: "widgets",
    timeline: "timeline-widget",
    smallestCompletionRate: "smallest-completion-rate",
    users: "users-widget",
  },
  timeline: {
    timelineEvents: "timeline-events",
    deleteEvent: "delete-event",
    restoreEvent: "restore-event",
  },
  publicCourseKey: "public-course-key",
  questions: {
    add: "add-question",
    appearances: "change-question-appearances",
    create: "create-question",
    courses: "questions-courses",
    delete: "delete-question",
    getSingle: "single-question",
    get: "questions",
    getAll: "questions-all",
    import: "import-questions",
    importPreview: "import-questions-preview",
    randomizedToggle: "randomized-question-toggle",
    remove: "remove-question",
    reorder: "questions-reorder",
    submit: "question-submit",
    tags: "questions-tags",
    update: "update-question",
    usages: "questions-usages",
    weights: "questions-weights",
  },
  microstats: {
    unitMicrostats: "unit-microstats",
    reportsMicrostats: "reports-microstats",
    singleReportsMicrostats: "single-reports-microstats",
  },
  traningMatrix: "traning-matrix",
  unitMatrix: "unit-matrix",
  certificates: {
    templates: "certificate-templates",
    templatePreview: "certificate-template-preview",
  },
  reports: {
    courseUsers: "course-users",
    unitReports: "unit-reports",
    unitUsers: "unit-users",
    unitTimeline: "unit-timeline",
    courseReports: "course-reports",
    userReports: "user-reports",
    userCourses: "user-courses",
    userCoursesMicrostats: "user-courses-microstats",
    branchReports: "branch-reports",
    groupReports: "group-reports",
    branchCourses: "branch-courses",
    branchUsers: "branch-users",
    branchTimeline: "branch-timeline",
    groupUsers: "group-users",
    groupCourses: "group-courses",
    groupTimeline: "group-timeline",
    userBadges: "user-badges-reports",
    courseTimeline: "course-timeline",
    userTimeline: "user-timeline",
    scheduledReports: "scheduled-reports",
    deleteScheduledReport: "delete-scheduled-report",
    userScheduledReports: "user-scheduled-reports",
    gamificationSettingsUser: "gamification-settings-user",
    unitProgress: "unit-progress",
    singleCourseScheduleReports: "single-course-schedule-reports",
    unitMatrixScheduledReports: "unit-matrix-scheduled-reports",
    trainingMatrixScheduledReports: "training-matrix-scheduled-reports",
    groupScheduledReports: "group-scheduled-reports",
    branchScheduledReports: "branch-scheduled-reports",
    iltSessions: "ilt-sessions",
    assignmentProgress: "assignment-progress",
    instructorReply: "instructor-reply",
    assignmentScheduledReports: "assignment-scheduled-reports",
    testAnalysis: "test-analysis",
    surveyAnalysis: "survey-analysis",
    freeTextAnswers: "free-text-answers",
    freeTextAnswersSurvey: "free-text-answers-survey",
    testAttempts: "test-attempts",
    scormAnalysis: "scorm-analysis",
    customReports: "custom-reports",
    surveyAttempts: "survey-attempts",
    customReport: "custom-report",
    customReportRun: "custom-report-run",
    customReportDelete: "custom-report-delete",
    customReportsClone: "custom-reports-clone",
    customReportsFilterBranches: "custom-reports-filter-branches",
    customReportsFilterGroups: "custom-reports-filter-groups",
    customReportsFilterCourses: "custom-reports-filter-courses",
    customReportsFilterUserTypes: "custom-reports-filter-user-types",
    customReportsFilterUserCustomFields: "custom-reports-filter-user-custom-fields",
    customReportsFilterCourseCustomFields: "custom-reports-filter-course-custom-fields",
    iltScheduledReports: "ilt-scheduled-reports",
    customReportPost: "custom-report-post",
    customReportPut: "custom-report-put",
    trainingProgressReports: "training-progress-reports",
    scormScheduledReports: "scorm-scheduled-reports",
    testScheduledReports: "test-scheduled-reports",
    scormUserAttempts: "scorm-attempts",
    markQuestionCorrect: "mark-question-correct",
    progressWidget: "progress-widget",
    activityWidget: "activity-widget",
    scormTitlePatch: "scorm-title-patch",
    scormTitleReset: "scorm-title-reset",
    scormAnswerPatch: "scorm-answer-patch",
    scormAnswerReset: "scorm-answer-reset",
    assignmentUserSubmission: "assignment-user-submission",
    testUserSubmission: "test-user-submission",
    surveyUserSubmission: "survey-user-submission",
    groupProgress: "group-progress",
    iltSessionsFilters: "ilt-sessions-filters",
    branchInformation: "branch-information",
    branchMicrostats: "branch-microstats",
    courseInformation: "course-information",
    courseMicrostats: "course-microstats",
    branchActivityWidget: "branch-activity-widget",
    groupActivityWidget: "group-activity-widget",
    userActivityWidget: "user-activity-widget",
  },
  notifications: {
    notifications: "notifications",
    deleteNotificationItem: "notification-delete",
    getNotificationItem: "notification-get",
    createNotificationItem: "notification-add",
    editNotificationItem: "notification-edit",
    previewNotification: "preview-notification",
    notificationHistory: "notification-history",
    notificationHistoryItem: "notification-history-item",
    resend: "resend-notification-history-item",
    deleteHistory: "delete-notification-history",
    notificationPending: "notification-pending",
    notificationPendingItem: "notification-pending-item",
    deletePending: "delete-notification-pending",
    deletePendingItem: "delete-notification-pending-item",
    systemNotifications: "system-notifications",
    systemNotificationEdit: "system-notification-edit",
    systemNotificationSmartTags: "system-smart-tags",
  },
  tableExports: {
    categoriesTable: "categories-export",
    courses: "courses-export",
    courseFilesTable: "course-files-table-export",
    courseUsersTable: "course-users-table-export",
    courseBranchesTable: "course-branches-table-export",
    courseGroupsTable: "course-groups-table-export",
    coursesTable: "courses-table-export",
    courseTimeline: "course-timeline-export",
    customCourseFieldsExport: "custom-course-fields-export",
    reportsBranchUsers: "reports-branch-users-export",
    branchFiles: "branch-files-export",
    branchUsers: "branch-users-export",
    branchCourses: "branch-courses-export",
    branchTimeline: "branch-timeline-export",
    existingQuestions: "test-existing-questions-export",
    groupCourses: "group-courses-export",
    groupFilesExport: "group-files-export",
    groupUsers: "group-users-export",
    groupUsersReports: "group-users-reports-export",
    groupsReport: "groups-report-export",
    timelineExport: "timeline-export",
    groupTimeline: "group-timeline-export",
    userTimeline: "user-timeline-export",
    branches: "branches-export",
    branchReports: "branch-reports-export",
    userBranches: "user-branches-export",
    userFiles: "user-files-export",
    userGroups: "user-groups-export",
    userCourses: "user-courses-export",
    usersExports: "user-exports",
    languageOverridesExports: "language-overrides-exports",
    userReportsExports: "user-reports-exports",
    userUnitsReportExport: "user-units-report-export",
    userCertificatesExport: "user-certificates-export",
    userCoursesReportExport: "user-courses-report-export",
    unitsReportExport: "units-report-export",
    unitUsersReportsExports: "unit-users-reports-exports",
    unitTimelineReportExport: "unit-timeline-report-export",
    branchCoursesExport: "branch-courses-export",
    groupCoursesExport: "group-courses-export",
    task: "csv-task",
    groups: "groups-export",
    myProgressCourses: "my-progress-courses-export",
    myProgressUnits: "my-progress-units-export",
    myProgressTimeline: "my-progress-timeline-export",
    courseUnitsReportExport: "course-units-report-export",
    courseUsersReportExport: "course-users-report-export",
    scheduledReportsExport: "scheduled-reports-export",
    customReportsExport: "custom-reports-export",
    customReportRunExport: "custom-report-run-export",
    iltSessionsReports: "ilt-sessions-reports-export",
    incomingMessagesExport: "incoming-messages-export",
    outgoingMessagesExport: "outgoing-messages-export",
    discussionsExport: "discussions-export",
    myGroupsExport: "my-groups-export",
    myFilesExport: "my-files-export",
    invoices: "invoices-export",
    ecommerceCoupons: "ecommerce-coupons-export",
    pendingNotificationsExport: "pending-notifications-export",
    notificationsHistoryExport: "notifications-history-export",
    notificationsOverviewExport: "notifications-overview-export",
    automationsExport: "automations-export",
    sessionsUsersExport: "post-session-users-export",
  },
  excelExports: {
    branchOverviewExport: "branch-overview-export",
    groupOverviewExport: "group-overview-export",
    userOverviewExport: "user-overview-export",
    trainingMatrixExport: "training-matrix-export",
    singleCourseReportExport: "single-course-report-export",
    scheduleReportPut: "user-scheduled-report-put",
    scheduleReportPost: "user-scheduled-report-post",
    unitMatrixExport: "unit-matrix-export",
    assignmentOverviewExport: "assignment-overview-export",
    iltOverviewExport: "ilt-overview-export",
    trainingProgressExport: "training-progress-export",
    scormOverviewExport: "scorm-overview-export",
    surveyOverviewExport: "survey-overview-export",
    testOverviewExport: "test-overview-export",
    customReportRunExport: "custom-report-run-export",
  },
  gradingHub: {
    getSingleSubmission: "get-single-submission",
    getSubmissions: "get-submissions",
    gradeSubmission: "grade-submission",
    resetSubmission: "reset-submission",
    pendingCount: "pending-submissions-count",
  },
  ilts: {
    session: "get-ilts-session",
    sessions: "get-ilts-sessions",
    sessionsUsers: "get-ilts-sessions-users",
    unenrolledUsers: "get-unenrolled-users",
    postAttendanceSheet: "post-attendance-sheet",
    postRegisterUser: "post-register-user",
  },
  help: {
    accountManager: {
      create: "create-account-manager-ticket",
      attachment: "account-manager-attachment",
    },
    articles: "get-help-articles",
    supportServices: "get-support-services",
    ticket: {
      create: "create-ticket",
      attachment: "ticket-attachment",
    },
  },
  onboarding: {
    getOnboardingSettings: "get-onboarding-settings",
    saveOnboardingSettings: "save-onboarding-settings",
    restartTour: "restart-tour",
  },
  newInterface: {
    putNewInterfaceDismiss: "put-new-interface-dismiss",
  },
  craft: {
    credits: "credits",
    creditCatalog: "credit-catalog",
    creditRequest: "credit-request",
  },
  tagManager: {
    track: "track-tag-manager",
  },
  conferences: {
    conferences: "conferences",
    create: "create-conference",
    delete: "delete-conference",
    notifyUsers: "conference-notify-users",
    users: "conference-users",
    userAdd: "conference-user-add",
    userRemove: "conference-user-remove",
    uninvitedUsers: "conference-uninvited-users",
  },
  zendesk: {
    token: "zendesk-token",
  },
} as const;
