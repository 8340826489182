import React, { FC } from "react";
import { unitPageLayout } from "./styles";
import Content from "./components/Content";
import Sidebar from "./components/Sidebar";
import ContentHeader from "./components/ContentHeader";
import ContentBody, { ContentBodyProps } from "./components/ContentBody";
import ContentFooter from "./components/ContentFooter";

type UnitPageLayoutProps = {
  Sidebar: FC;
  Content: FC;
  ContentHeader: FC;
  ContentBody: FC<ContentBodyProps>;
  ContentFooter: FC;
};

const UnitPageLayout: FC & UnitPageLayoutProps = ({ children }) => (
  <div css={unitPageLayout}>{children}</div>
);

UnitPageLayout.Sidebar = Sidebar;
UnitPageLayout.Content = Content;
UnitPageLayout.ContentHeader = ContentHeader;
UnitPageLayout.ContentBody = ContentBody;
UnitPageLayout.ContentFooter = ContentFooter;

export default UnitPageLayout;
