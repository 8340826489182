// Packages or third-party libraries
import React, { FC, useState } from "react";
import { Button, Text } from "@epignosis_llc/gnosis";
import { CourseExitSVG } from "@epignosis_llc/gnosis/icons";
import { useNavigate } from "react-router-dom";
import { SerializedStyles } from "@emotion/react";

// Styles
import { courseHeaderStyles } from "./styles";

// Components
import TitleContainer from "./TitleContainer";
import Rating from "./Rating";
import Progress from "./Progress";
import Restrictions from "./Restrictions";
import CourseButton from "./CourseButton/CourseButton";
import Bundles from "./Bundles";
import ExpiresDate from "./ExpiresDate";
import CoverImage from "./CoverImage";
import IntoVideo from "./IntoVideo";
import { SendMessageMassActionDrawer } from "@views/Users";

// Utils, hooks
import { getFlatUnits, getUnitIdToContinue } from "@utils/helpers";
import { getCourseCategoryText } from "./helpers";
import { isCourseButtonVisible, isCourseButtonDisabled } from "./CourseButton/helpers";
import { useConfigurationStore, useUnitStore } from "@stores";
import { useApplyTranslations } from "@hooks";
import permissions from "@utils/permissions";

// Other imports
import { Course, CourseCategory, Section } from "types/entities";

type CourseHeaderProps = {
  course: Course;
  categoryTree: CourseCategory[];
  sections: Section[];
  isCatalogCourseView?: boolean;
  backLink?: string;
};

const CourseHeader: FC<CourseHeaderProps> = ({
  course,
  categoryTree,
  sections,
  backLink,
  isCatalogCourseView = false,
}) => {
  const { t } = useApplyTranslations();
  const { gamificationSettings, userProfileData, catalogSettings } = useConfigurationStore();
  const navigate = useNavigate();
  const { isCourseHeaderSticky } = useUnitStore();

  const {
    name,
    category,
    code,
    cover_image,
    progress,
    enrolled,
    intro_video,
    level,
    rules,
    starts_at,
    user_access_expires_at,
    bundles,
    availability,
    expires_at,
    role_in_course,
  } = course;
  const { prerequisites } = rules;
  const { completion_status } = progress ?? {};
  const [isSendMessageDrawerOpen, setSendMessageDrawerOpen] = useState(false);
  const isUserEnrolled = Boolean(enrolled);
  const { categoryText, allCategoryParent } = getCourseCategoryText({ category, categoryTree });
  const showAdditionalInfo = !isCourseHeaderSticky;

  // restrictions
  const isLevelEnabled = Boolean(gamificationSettings?.levels?.enabled);
  const { canSendMessageToInstructors } = permissions.messagesPermissions;
  const userLevel = userProfileData?.level ?? 0;
  const courseLevel = level ?? 0;
  const unlocksOnHigherLevel = isLevelEnabled ? userLevel < courseLevel : false;
  const showBundleList = isCatalogCourseView && bundles && bundles.length > 0;
  const showSendInstructorMessage = canSendMessageToInstructors() && completion_status === "failed";

  // course button
  const isSubscriptionEnabled = Boolean(catalogSettings?.subscription.enabled);
  const units = getFlatUnits(sections);
  const hasUnits = Boolean(units.length);
  const continueUnitId = hasUnits ? getUnitIdToContinue(units) : null;
  const isButtonVisible = isCourseButtonVisible({ isUserEnrolled, continueUnitId, availability });
  const isButtonDisabled = isCourseButtonDisabled({
    isSubscriptionEnabled,
    isCatalogCourseView,
    unlocksOnHigherLevel,
    availability,
    roleInCourse: role_in_course,
  });
  const showButton = (isButtonVisible && !isButtonDisabled) || !isCourseHeaderSticky;

  return (
    <>
      <div
        css={(theme): SerializedStyles =>
          courseHeaderStyles(theme, { isSticky: isCourseHeaderSticky })
        }
      >
        {backLink && (
          <Button
            noGutters
            color="primaryLight"
            className="back-button"
            onClick={(): void => navigate(backLink)}
          >
            <CourseExitSVG height={32} />
          </Button>
        )}

        <div className="start-container">
          <TitleContainer
            category={category}
            categoryText={categoryText}
            allCategoryParent={allCategoryParent}
            name={name}
            code={code}
          />

          {showAdditionalInfo && (
            <div className="additional-info-container">
              <Progress
                isUserEnrolled={isUserEnrolled}
                progress={progress}
                availability={availability}
              />
              <div className="restrictions-wrapper">
                <Restrictions
                  level={courseLevel}
                  unlocksOnHigherLevel={unlocksOnHigherLevel}
                  prerequisites={prerequisites}
                  startsAt={starts_at}
                  userAccessExpiresAt={user_access_expires_at}
                />
                <ExpiresDate expiresAt={expires_at} />

                {showSendInstructorMessage && (
                  <Text fontSize="sm">
                    <span
                      className="clickable-text"
                      onClick={(): void => setSendMessageDrawerOpen(true)}
                    >
                      {t("myCourses.contactInstructor")}
                    </span>
                  </Text>
                )}
              </div>
              {showBundleList && <Bundles bundles={bundles} />}
              <Rating course={course} />
            </div>
          )}
        </div>

        <div className="end-container">
          <CoverImage coverImage={cover_image} />
          {showButton ? (
            <CourseButton
              course={course}
              isCatalogCourseView={isCatalogCourseView}
              isVisible={isButtonVisible}
              isDisabled={isButtonDisabled}
              unlocksOnHigherLevel={unlocksOnHigherLevel}
              categoryText={categoryText}
              continueUnitId={continueUnitId}
            />
          ) : (
            <Restrictions
              level={courseLevel}
              unlocksOnHigherLevel={unlocksOnHigherLevel}
              prerequisites={prerequisites}
              startsAt={starts_at}
              userAccessExpiresAt={user_access_expires_at}
            />
          )}
          <IntoVideo introVideo={intro_video} name={name} />
        </div>
      </div>

      {isSendMessageDrawerOpen && showSendInstructorMessage && (
        <SendMessageMassActionDrawer
          isDrawerOpen={isSendMessageDrawerOpen}
          onClose={(): void => setSendMessageDrawerOpen(false)}
          courseInstructorId={course.id}
          hideAttachments={true}
          headerText={t("myCourses.contactInstructor")}
        />
      )}
    </>
  );
};

export default CourseHeader;
