import React from "react";
import { t } from "@utils/i18n";
import { SelectOption } from "types/common";
import { MassActionType, WithIdAndName } from "./types";
import ButtonWithDropdown from "../ButtonWithDropdown/ButtonWithDropdown";
import { resetDropdownOptions } from "./constants";

export const resolveKey = (type?: MassActionType): string => {
  switch (type) {
    case "add_to_groups":
    case "remove_from_groups": {
      return "group_ids";
    }
    case "add_to_category": {
      return "category_id";
    }
    case "add_to_branches":
    case "remove_from_branches": {
      return "branch_ids";
    }
    case "add_course_to_all_branches":
    case "remove_course_from_all_branches":
    case "add_course_to_all_groups":
    case "remove_course_from_all_groups":
      return "course_id";
    case "reset":
      return "remove_certificates";
    default:
      return "";
  }
};

export const resolveValue = (selectedOption: SelectOption | SelectOption[]): number | number[] => {
  const isArray = Array.isArray(selectedOption);

  if (isArray) {
    const selectedValues = selectedOption.map((option) => Number(option.value));
    return selectedValues;
  }

  return Number(selectedOption.value);
};

export const resolveLabel = (type: MassActionType): string => {
  switch (type) {
    case "add_to_groups":
    case "remove_from_groups": {
      return t("general.group");
    }
    case "add_to_category": {
      return t("general.category");
    }
    case "add_to_branches":
    case "remove_from_branches": {
      return t("general.branch");
    }
    case "add_course_to_all_branches":
    case "remove_course_from_all_branches":
    case "add_course_to_all_groups":
    case "remove_course_from_all_groups":
      return t("general.course");
    default:
      return "";
  }
};

export const resolveSelectOptions = <T extends WithIdAndName>(array: T[]): SelectOption[] => {
  return (
    array?.map((item) => ({
      value: item.id.toString(),
      label: item.name,
    })) ?? []
  );
};

export const resetConfirmationButton = (
  handleConfirmMassAction: (confirmationButtonItem?: boolean) => void,
  shouldDisableApplyButton: boolean,
  shouldNotShowExtraOptions: boolean,
): JSX.Element => {
  return (
    <ButtonWithDropdown
      list={shouldNotShowExtraOptions ? [] : resetDropdownOptions}
      disabled={shouldDisableApplyButton}
      color="danger"
      onButtonClick={(): void => {
        handleConfirmMassAction();
      }}
      onListItemClick={(): void => {
        handleConfirmMassAction(true);
      }}
    >
      {t("general.reset")}
    </ButtonWithDropdown>
  );
};

export const resolveDisableButton = (
  total: number,
  hasReachedMaxUsers: boolean,
  shouldTriggerCount: boolean,
  selectedItems: number | number[] | null | undefined,
): boolean => {
  if (hasReachedMaxUsers) return true;

  if (total === 0) return true;

  if (
    !shouldTriggerCount &&
    (selectedItems === null || (Array.isArray(selectedItems) && selectedItems.length === 0))
  ) {
    return true;
  }

  return false;
};
