import { Button, Text } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { CirclePlaySVG } from "@epignosis_llc/gnosis/icons";
import { watchDemoButtonStyles } from "./WatchDemoButton.styles";
import { useApplyTranslations } from "@hooks";
import useWatchDemo from "./useWatchDemo";

const WatchDemoButton: FC = () => {
  const { t } = useApplyTranslations();
  const { handleWatchDemoClick } = useWatchDemo();

  return (
    <Button
      css={watchDemoButtonStyles}
      variant="solid"
      color="primaryLight"
      iconBefore={CirclePlaySVG}
      className="watch-demo-button"
      onClick={handleWatchDemoClick}
      aria-role={t("consensus.watchDemo")}
    >
      <Text as="p" fontSize="sm" className="button-text">
        {t("consensus.watchDemo")}
      </Text>
    </Button>
  );
};

export default WatchDemoButton;
